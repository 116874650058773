import { Box } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { Device, Light } from '../../../../Interface'
import { ToggleButton } from './../ToggleButton'

interface Props {
    device: Light
    updateDevice: (device: Device) => void
}

export const LightControl: FunctionComponent<Props> = props => {
    const { device, updateDevice } = props
    const value = device.light.status ? 'ON' : 'OFF'
    const onChange = (value: string) => {
        const newDevice: Device = {
            ...device,
            light: {
                ...device.light,
                status: value === 'ON' ? 1 : 0
            }
        }
        updateDevice(newDevice)
    }
    return (
        <Box display="flex" justifyContent="center">
            <Box width="150px">
                <ToggleButton
                    value={value}
                    onChange={onChange}
                    labels={['ON', 'OFF']}
                />
            </Box>
        </Box>
    )
}
