import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Hidden,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Axios from 'axios';
import interact from 'interactjs';
import React, {
  createRef,
  FunctionComponent,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Slider from 'react-slick';
import { Context } from '../../Context';
import { MapImage, ScenarioInterface, SubLevel } from '../../Interface';
import history from '../history';
import { MapContextProvider } from './MapContext';
import { RenderLevel } from './RenderLevel';
import { useMapSize } from './useMapSize';
type MatchType = { params: { id: string } };

interface Props {
  textMode: boolean;
  setFavorite: (favoritePath?: number[]) => void;
  favoritePath?: number[];
  match: MatchType;
  toScenario: (scenario: ScenarioInterface) => void;
}

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const Devices: FunctionComponent<Props> = (props) => {
  // const theme = useTheme()
  const { textMode, match, setFavorite, favoritePath, toScenario } = props;
  const context = useContext(Context);
  const [swipe, setSwipe] = useState(true);
  const mapSize = useMapSize();
  const path: number[] = getPath(match.params.id);
  const slider = useRef<Slider>(null);
  const isScreenSmall = useMediaQuery('(max-width: 992px)');
  const isTextMode = textMode || isScreenSmall;

  const levels = context.allLevels;
  const neighbours = getNeighbours(levels, path);
  const currentLevelId = path.slice(-1)[0];
  const currentSlide = Math.max(
    neighbours.findIndex((lv) => lv.id === currentLevelId),
    0
  );
  const [neighboursMap, setNeighboursMap] = useState(neighbours);
  const oldCurrentSlide = usePrevious(currentSlide);
  const [scenario, setScenario] = useState(context.scenario);
  const [elRefs, setElRefs] = React.useState<RefObject<HTMLDivElement>[]>([]);

  useEffect(() => {
    if (context.images.length === 0 && !isScreenSmall && !isTextMode)
      Axios.get('/apiimages')
        .then((res) => res.data)
        .then(context.setImages);
  }, []);
  useEffect(() => {
    slider.current?.slickGoTo(currentSlide);
    elRefs[currentSlide]?.current?.scrollIntoView({
      behavior: 'smooth',
    });
    if (
      oldCurrentSlide &&
      oldCurrentSlide >= neighbours.length &&
      currentSlide !== neighbours.length - 1
    ) {
      setNeighboursMap([]);
      setTimeout(() => {
        setNeighboursMap(neighbours);
        slider.current?.slickGoTo(currentSlide);
      }, 500);
    } else {
      setNeighboursMap(neighbours);
    }
  }, [JSON.stringify(path)]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(neighbours.length)
        .fill(undefined)
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [neighbours.length]);

  useEffect(() => {
    setNeighboursMap(neighbours);
  }, [JSON.stringify(levels)]);

  useEffect(() => {
    interact('.draggable').unset();
    interact('.draggable').draggable({
      inertia: true,
      autoScroll: true,
      onmove: (event: Interact.InteractEvent) => {
        if (mapSize.width && mapSize.height) {
          let target = event.target;
          let left = (parseFloat(target.style.left) * mapSize.width) / 100;
          let top = (parseFloat(target.style.top) * mapSize.height) / 100;
          let x = left + event.dx;
          let y = top + event.dy;
          target.style.left = (x / mapSize.width) * 100 + '%';
          target.style.top = (y / mapSize.height) * 100 + '%';
        }
      },
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true,
        }),
      ],
    });
  }, [mapSize]);

  return (
    <MapContextProvider
      value={{
        setFavorite: setFavorite,
        favoritePath: favoritePath,
        toScenario: toScenario,
        textMode: isTextMode,
        path: path,
        setSwipe: setSwipe,
        scenario: scenario,
        setScenario: setScenario,
      }}
    >
      <Box display='flex' flexDirection='column' width='100%' height='100vh'>
        <Box
          flexGrow='1'
          maxWidth='100%'
          display='flex'
          className='devices-container'
          flexDirection='column'
        >
          {neighboursMap.length ? (
            <Slider
              ref={slider}
              initialSlide={currentSlide}
              speed={200}
              waitForAnimate={true}
              afterChange={afterChange}
              arrows={false}
              swipe={swipe}
            >
              {neighboursMap.map((n) => (
                <RenderLevel key={n.id} level={n} />
              ))}
            </Slider>
          ) : (
            <Box
              flexGrow='1'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        {!isTextMode && (
          <Hidden smDown>
            <Box
              width='100%'
              height='200px'
              flexShrink={0}
              display='flex'
              alignContent='center'
              paddingLeft={3}
              paddingRight={3}
              style={{ overflowX: 'auto' }}
            >
              {neighbours.map((n, index) => {
                const image = context.images.find((im) => im.id === n.id);
                const name = context.custom[context.language].levels.name[n.id];
                return (
                  <Box
                    key={`nav-${n.id}`}
                    padding={1}
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='230px'
                    flexShrink='0'
                    onClick={() => afterChange(index)}
                    style={{
                      opacity: n.id === currentLevelId ? 1 : 0.5,
                    }}
                    {...{ ref: elRefs[index] }}
                  >
                    <Card>
                      <CardMedia component='img' image={image?.src} />
                    </Card>
                    <Typography align='center'>{name}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Hidden>
        )}
      </Box>
    </MapContextProvider>
  );

  function getPath(id: string): number[] {
    return id.split('/').map(Number);
  }

  function getNeighbours(levels: SubLevel[], path: number[]): SubLevel[] {
    if (path.length <= 1) {
      const level = levels.find((lev) => lev.id === path[0]) as SubLevel;
      return level ? [level] : [];
    } else {
      const parentLevelId = path.slice(-2)[0];
      const parentLevel = levels.find(
        (lev) => lev.id === parentLevelId
      ) as SubLevel;
      return parentLevel.subLevels.map(
        (ol) => levels.find((lev) => lev.id === ol.id) as SubLevel
      );
    }
  }

  function afterChange(currentSlide: number) {
    if (neighbours[currentSlide]) {
      history.replace(
        '/devices/' +
          [...path.slice(0, -1), neighbours[currentSlide].id].join('/')
      );
    }
  }
};
