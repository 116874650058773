import { Box, Button, Slider, Typography, useTheme } from "@material-ui/core";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Context } from "../../../../Context";
import {
  Device,
  fanModeEnum,
  occupancyEnum,
  thermoModeEnum,
  Thermostat,
} from "../../../../Interface";

interface Props {
  device: Thermostat;
  updateDevice: (device: Device) => void;
}

const marks = [
  { value: -1, label: "Auto" },
  { value: 0, label: "Off" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
];

const marksMap: { [key in string]: string } = {
  "-1": "Auto",
  "0": "Off",
  "1": "1",
  "2": "2",
  "3": "3",
};

export const ThermostatControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  const theme = useTheme();
  const context = useContext(Context);

  const [temperature, setTemperature] = useState(
    device.thermostat.offset?.value ?? device.thermostat.setpoint.value
  );
  const [mode, setMode] = useState(device.thermostat.occupancy);
  const [fanMode, setFanMode] = useState(device.thermostat.fanMode);
  const [active, setActive] = useState(false);
  let timeout: NodeJS.Timeout | undefined;

  useEffect(() => {
    if (!active) {
      setTemperature(
        device.thermostat.offset?.value ?? device.thermostat.setpoint.value
      );
    }
  }, [
    device.thermostat.offset?.value,
    device.thermostat.setpoint.value,
  ]);

  useEffect(() => {
    setMode(device.thermostat.occupancy);
  }, [device.thermostat.occupancy]);

  useEffect(() => {
    if (!active) {
      setFanMode(device.thermostat.fanMode);
    }
  }, [device.thermostat.fanMode]);

  const n = device.thermostat.offset
    ? (device.thermostat.offset.max - device.thermostat.offset.min) * 2 + 1
    : (device.thermostat.setpoint.max - device.thermostat.setpoint.min) * 2 + 1;

  const offsetMarks = Array.from(
    new Array(n),
    (_, i) =>
      (i -
        (device.thermostat.offset?.max || device.thermostat.setpoint.max) * 2) /
      2
  ).map((x) => ({ value: x, label: x % 1 === 0 ? x.toString() : undefined }));
  const fanModeChange = (
    _ev: React.ChangeEvent<{}>,
    val: number | number[]
  ) => {
    let newValue: number = Number(val);

    const fanSpeed = device.thermostat.fanSpeed;
    if (fanSpeed !== undefined) {
      updateDevice({
        ...device,
        thermostat: {
          ...device.thermostat,
          fanMode: newValue,
          fanSpeed: {
            ...fanSpeed,
            value: Math.round(Math.max(0, newValue * 33.3)),
          },
        },
      });
      }
      const fanMode = device.thermostat.fanMode;
      if ((fanMode !== undefined) && (fanMode !== fanModeEnum.UNDEFINED)) {
          updateDevice({
              ...device,
              thermostat: {
                  ...device.thermostat,
                  fanMode: newValue
              }
          });
      }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box
        minWidth="125px"
        borderRadius="10px"
        padding="10px"
        display="flex"
        flexDirection="column"
        bgcolor={theme.palette.background.default}
      >
        <Button
          style={{ flexGrow: 1, minHeight: "50px", marginBottom: "10px" }}
          variant={mode === occupancyEnum.ECO ? "contained" : undefined}
          onClick={() => {
            setMode(occupancyEnum.ECO);
            updateDevice({
              ...device,
              thermostat: {
                ...device.thermostat,
                occupancy: occupancyEnum.ECO,
              },
            });
          }}
          color="primary"
        >
          ECO
        </Button>
        <Button
          style={{ flexGrow: 1, minHeight: "50px" }}
          color="primary"
          variant={mode === occupancyEnum.COMFORT ? "contained" : undefined}
          onClick={() => {
            setMode(occupancyEnum.COMFORT);
            updateDevice({
              ...device,
              thermostat: {
                ...device.thermostat,
                occupancy: occupancyEnum.COMFORT,
              },
            });
          }}
        >
          COMFORT
        </Button>
      </Box>
      <Box flexGrow="1" display="flex" flexDirection="column" paddingLeft={3}>
        <Box
          textAlign="right"
          display="flex"
          justifyContent="flex-start"
          flexDirection="row-reverse"
        >
          <Box>
            <Typography variant="h4" noWrap>
              {Number(device.thermostat.temperature).toFixed(1)} ˚C
            </Typography>
            <Typography variant="subtitle1" noWrap>
              {Number(device.thermostat.setpoint.value).toFixed(1)} ˚C
              {device.thermostat.offset &&
                ` (${Number(device.thermostat.offset.value).toFixed(1)} ˚C)`}
            </Typography>
            <Typography variant="h6" noWrap>
              {Boolean(device.thermostat.humidity) &&
                `${device.thermostat.humidity}%`}
            </Typography>
          </Box>
          <Box textAlign="left" margin={1} minWidth="70px">
            <i
              className={`far fa-snowflake ${
                device.thermostat.thermoMode !== thermoModeEnum.COOL
                  ? "off-icon"
                  : ""
              }`}
              style={{
                visibility:
                  device.thermostat.thermoMode !== thermoModeEnum.COOL &&
                  device.thermostat.thermoMode !== thermoModeEnum.HEAT
                    ? "hidden"
                    : "visible",
              }}
            />{" "}
            <i
              className={`icon-riscaldamento ${
                device.thermostat.thermoMode !== thermoModeEnum.HEAT
                  ? "off-icon"
                  : ""
              }`}
              style={{
                visibility:
                  device.thermostat.thermoMode !== thermoModeEnum.COOL &&
                  device.thermostat.thermoMode !== thermoModeEnum.HEAT
                    ? "hidden"
                    : "visible",
              }}
            />{" "}
            {device.thermostat.windowAlarm && (
              <i
                className={`fas fa-table ${
                  device.thermostat.windowAlarm === true
                    ? "color-accent"
                    : "off-icon"
                }`}
              />
            )}{" "}
            {device.thermostat.condensationAlarm && (
              <i
                className={`fas fa-tint ${
                  device.thermostat.condensationAlarm === true
                    ? "color-accent"
                    : "off-icon"
                }`}
              />
            )}
            {device.thermostat.fanSpeed !== undefined && (
              <Box>
                <i className="fas fa-wind" />{" "}
                {device.thermostat.fanSpeed.value + "%"}
              </Box>
            )}
          </Box>
        </Box>
        <Typography variant="h6" align="right">
          {occupancyEnum[device.thermostat.occupancy]}
        </Typography>
        <Box padding={2}>
          <Typography>
            {context.i18n[context.language].devices.thermostat.adjustment}:
          </Typography>
          <Slider
            value={temperature}
            valueLabelDisplay="auto"
            marks={offsetMarks}
            step={0.5}
            onChange={(_, value) => setTemperature(value as number)}
            onChangeCommitted={(_, value) => {
              const key = device.thermostat.offset ? "offset" : "setpoint";
              if (timeout) {
                clearTimeout(timeout);
              }
              setActive(true);
              updateDevice({
                ...device,
                thermostat: {
                  ...device.thermostat,
                  [key]: {
                    ...device.thermostat[key],
                    value: value as number,
                  },
                },
              });
              timeout = setTimeout(() => setActive(false), 5000);
            }}
            min={
              device.thermostat.offset?.min || device.thermostat.setpoint.min
            }
            max={
              device.thermostat.offset?.max || device.thermostat.setpoint.max
            }
          />
              </Box>
              {device.thermostat.fanMode !== undefined && device.thermostat.fanMode !== fanModeEnum.UNDEFINED && (
                   
                          <Box padding={2}>
                      <Typography>
                          {context.i18n[context.language].devices.thermostat.fancoil}:
                      </Typography>
                              <Slider
                                  value={fanMode}
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={(value) => marksMap[value.toString()]}
                                  marks={marks}
                                  min={-1}
                                  step={null}
                                  onChange={(_, value) => setFanMode(value as number)}
                                  onChangeCommitted={fanModeChange}
                                  max={3}
                              />
                          </Box>
                      
              )}
      </Box>
    </Box>
  );
};
